<template>
  <div class="banner-manage" v-if="show">
    <div class="banner-manage-box">
      <i class="close el-icon-close" @click="close"></i>
      <h3 class="title">新建Banner</h3>
      <p class="word">
        <span class="word-title">上传banner图片</span
        ><span class="word-text">推荐上传尺寸为：1380X520 px</span>
      </p>
      <upload :info="imgInfo" class="upload-img" @getimage="getimage" />
      <p class="word">
        <span class="word-title">跳转链接</span>
      </p>
      <el-input v-model="linkUrl" placeholder="请输入跳转链接"></el-input>
      <footer class="banner-manage-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="debounce(confirm, 1000)">确认</el-button>
      </footer>
    </div>
  </div>
</template>
<script>
import Upload from "@/components/common/Upload";
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";

export default defineComponent({
  components: { Upload },
  model: {
    prop: 'show',
    event: 'change'
  },
  props: {
    show: { type: Boolean, default: false },
    info: {},
    url: { type: String, default: '' },
  },
  setup(props, { emit, root }) {
    const imgInfo = reactive({url: '', width: 1380, height: 520});
    const linkUrl = ref('')
    let info = {}
    const close = ()=>{
      info = {}
      imgInfo.url = ''
      linkUrl.value = ''
      emit('change', false)
    }
    const confirm = async () => {
      try {
        if(!imgInfo.url) {
          root.$message.warning('请选择图片')
          return
        }
        if(!linkUrl.value) {
          root.$message.warning('请输入跳转链接')
          return
        }
        const res = await root.$axios.post('/cms/banner/save', {
          id: info.id || undefined,
          img: imgInfo.url,
          redirect_url: linkUrl.value
        })
        if(res.code === 10000) {
          root.$message.success(info.id?'修改成功':'创建成功')
          close()
        }
      } catch (error) {
        console.error(error)
      }
    }
    const getimage = data=>{
      imgInfo.url = data
    }
    watch(props.info,val=>{
      info = Object.assign({},val.data)
      imgInfo.url = info.url
      linkUrl.value = info.redirect_url
    })
    return {
      close,
      confirm,
      linkUrl,
      imgInfo,
      getimage
    };
  },
});
</script>
<style lang="scss" scoped>
.banner-manage {
  @include go-wrap();
  .banner-manage-box {
    text-align: left;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 584px;
    height: 630px;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 26px;
    .close {
      cursor: pointer;
      font-size: 24px;
      position: absolute;
      right: 25px;
      top: 25px;
      color: $lightColor;
    }
    .title {
      font-size: 20px;
      font-weight: 500;
      color: $titleColor;
      line-height: 28px;
      margin-bottom: 26px;
    }
    .word {
      margin-bottom: 14px;
      .word-title {
        font-size: 16px;
        font-weight: 400;
        color: $titleColor;
        line-height: 22px;
      }
      .word-text {
        margin-left: 10px;
        color: $dangerColor;
      }
    }
    .upload-img {
      width: 536px;
      height: 326px;
      padding: 28px;
      background-color: #fafafa;
      margin-bottom: 36px;
    }
    .banner-manage-footer{
      margin-top: 36px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .el-button{
        width: 120px;
        height: 36px;
        margin-left: 24px;
      }
    }
  }
}
</style>