<template>
  <div class="table">
    <header class="header">
      <switch-text
        :left="'已启用'"
        :right="'已停用'"
        v-model="flag"
      ></switch-text>
      <div>
        <el-button type="primary" style="border-radius: 16px" @click="createBanner">+新建</el-button>
      </div>
    </header>
    <div v-if="flag">
      <DragTable :list-query="list" :header="header" :sort="true" @change="handle">
        <template #image="{row}"><img class="table-img" :src="`https://${row.img}`" /></template>
        <template #state="{ row }">
          <span class="status" :class="{ active: row.state !== 2 }">{{
            row.state === 2 ? `已启用` : `已停用`
          }}</span>
        </template>
        <template #control="{ row }">
          <div class="control">
            <span :class="{'active': row.state === 2}" @click="open(row)">{{row.state === 2 ?`停用`:'启用'}}</span>
            <span @click="bannerEdit(row)">编辑</span>
            <span class="gray" @click="del(row)">删除</span>
          </div>
        </template>
      </DragTable>
    </div>
    <DragTable v-if="!flag" :list-query="list" :header="header" :sort="false" @change="handle">
      <template #image="{row}"><img class="table-img" :src="`https://${row.img}`" /></template>
      <template #state="{ row }">
        <span class="status" :class="{ active: row.state !== 2 }">{{
          row.state === 2 ? `已启用` : `已停用`
        }}</span>
      </template>
      <template #control="{ row }">
        <div class="control">
          <span :class="{'active': row.state === 2}" @click="open(row)">{{row.state === 2 ?`停用`:'启用'}}</span>
          <span @click="bannerEdit(row)">编辑</span>
          <span class="gray" @click="del(row)">删除</span>
        </div>
      </template>
    </DragTable>
    <edit v-model="show" :info="detail"></edit>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";
import SwitchText from "@/components/common/SwitchText.vue";
import Edit from "./Edit.vue";

export default defineComponent({
  components: { SwitchText, Edit },
  setup(props, { root }) {
    const flag = ref(true);
    const show = ref(false);
    const detail = reactive({data: null})
    const header = [
      { name: "序号", value: "sort", fixed: "left", width: 100 },
      { name: "BannerID", value: "id", fixed: "left", width: 170 },
      { name: "图片", value: "img", align: "center", slot: "image", width: 320 },
      { name: "跳转链接", value: "redirect_url" },
      { name: "状态", value: "state", width: 100, slot: "state" },
      { name: "操作", value: "", fixed: "right", slot: "control", width: 140 },
    ];
    const list = reactive({data:[]});
    const createBanner = () => {
      show.value = true;
    }
    const getList = ()=>{
      try {
        root.$axios.get('/cms/banner/list', {
          params: {
            state: flag.value ? 2:1
          }
        })
        .then(res=>{
          if(!flag.value) {
            res.data.forEach((item,index)=>{
              item.sort = index+1
            })
          }
          list.data = res.data
        })
      } catch (error) {
        console.error(error)
      }
    }
    const bannerEdit = async (data) => {
      try {
        const res = await root.$axios.get('/cms/banner/detail/'+data.id)
        if(res.code === 10000) {
          res.data.url = res.data.img
          detail.data = res.data
          show.value = true;
        }
      } catch (error) {
        console.error(error)
      }
    };
    const del = async (data) => {
      if(data.state === 2) {
        root.$message.warning('启用状态无法删除')
        return
      }
      try {
        const confirm = await root.$goDialog("确定要删除吗？");
        if (!confirm) return;
        const res = await root.$axios.delete('/cms/banner/delete/'+data.id)
        if(res.code === 10000) {
          root.$message.success('删除成功')
          getList()
        }
      } catch (error) {
        console.error(error)
      }
    };
    const open = async (data) => {
      try {
        const confirm = await root.$goDialog(`确定要${data.state===2?'停用':'启用'}吗？`);
        if (!confirm) return;
        const res = await root.$axios.post('/cms/banner/set_state', {
          id: data.id,
          state: data.state === 1 ? 2:1
        })
        if(res.code === 10000) {
          root.$message.success(`${data.state===2?'停用':'启用'}成功`)
          getList()
        }
      } catch (error) {
        root.$message.error(error)
      }
    };
    const handle = async (data) => {
      try {
        const arr = []
        data.forEach(item => {
          arr.push({ id: item.id, sort: item.sort })
        });
        const res = await root.$axios.post('/cms/banner/sort',{
          sort_list: arr
        })
        if(res.code !== 10000) {
          root.$message.error(res.msg)
          getList()
        }
      } catch (error) {
        console.error(error)
      }
    }
    getList()
    watch(flag, () => {
      getList()
    });
    watch(show, (val) => {
      if(!val)
      getList()
    });
    return {
      list,
      header,
      flag,
      show,
      detail,
      del,
      open,
      handle,
      bannerEdit,
      createBanner,
    };
  },
});
</script>
<style lang="scss" scoped>
@mixin circle() {
  position: absolute;
  content: " ";
  display: block;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: $safeColor;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  justify-content: space-between;
}
.status {
  color: $safeColor;
  position: relative;
  color: $safeColor;
  padding-left: 13px;
  &::after {
    @include circle();
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  &.active {
    color: $wordColor;
    &::after {
      background-color: $wordColor;
    }
  }
}
.control {
  display: flex;
  span {
    margin-right: 15px;
    cursor: pointer;
    color: $mainColor;
    &:last-of-type(1) {
      margin-right: 0;
    }
    &.active{
      color: $dangerColor;
    }
    &.gray{
      color: $wordColor;
    }
  }
}
.table-img{
  width: 100%;
  object-fit: contain;
}
</style>
