<template>
    <div class="go-switch">
        <div :class="{'active': checked}" @click="sel(true)">{{left}}</div>
        <div :class="{'active': !checked}" @click="sel(false)">{{right}}</div>
    </div>
</template>
<script lang="js">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    model: {
        prop: 'checked',
        event: 'change'
    },
    props: {
        left: { type: String, default: '左边' },
        right: { type: String, default: '右边' },
        checked: { type: Boolean, default: true }
    },
    setup(props,{emit}) {
        const sel = (val) => {
            emit('change', val)
        }
        return {
            sel
        }
    },
})
</script>
<style lang="scss" scoped>
.go-switch{
    display: flex;
    min-width: 200px;
    position: relative;
    text-align: center;
    &::after{
        content: ' ';
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        height: 100%;
        width: 1PX;
        background-color: $mainColor;
    }
    &>div{
        width: 50%;
        border: 1PX solid $lightColor;
        line-height: 32px;
        cursor: pointer;
        color: $textColor;
        font-size: 14px;
        padding: 0 8px;
        &:nth-of-type(1) {
            border-right: 0;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }
        &:nth-of-type(2) {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            border-left: 0;
        }
        &.active{
            color: $ctrColor;
            border-color: $ctrColor;
        }
    }
}
</style>
